<style lang="scss" scoped>
@media (min-width: $tablet) {
  dl.horizontal > div {
    display: grid;
    grid-template-columns: 7rem auto;

    dt {
      grid-column-start: 1;
    }
    dd {
      grid-column-start: 2;
    }
  }
}

div {
  break-inside: avoid;
  page-break-inside: avoid;
}

.weekday {
  display: grid;
  grid-template-columns: 2.6rem auto;
}

.weekday-short,
.weekday-short-minimal {
  text-transform: capitalize;
}

.weekday-short-minimal:not(:last-of-type)::after {
  content: ', ';
}
.weekday-short-minimal:last-of-type::after {
  content: ' ';
}

.time {
  display: inline-block;
}

.weekday-times {
  align-items: center;
  display: flex;
}

.spacing:not(:last-child) {
  margin-bottom: 1rem;
}

.location-map-link {
  font-weight: $weight-bold;
}

address.single-line {
  span:not(:last-child)::after {
    content: ', ';
  }
  span {
    display: inline;
  }
}

.registrationopen {
  font-weight: $weight-semibold;
}

address {
  @media (max-width: $tablet) {
    span:not(:last-child)::after {
      content: ', ';
    }
  }

  @media (min-width: $tablet) {
    span:not(:last-child):not(:first-child)::after {
      content: ', ';
    }
    span.name {
      display: block;
    }
  }
}
</style>

<style lang="scss">
div.accessibility > p {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>

<template>
  <dl :class="{ horizontal }">
    <div v-if="show(CourseInfoDlField.Period)" :class="{ spacing }">
      <dt>{{ $t('courseInfoDl.period') }}</dt>
      <dd v-if="beginsAndEndsFromLesson">
        {{ begins | dateTimeRange(ends) }}
      </dd>
      <dd v-else>
        {{ begins | dateRange(ends) }}
      </dd>
    </div>

    <div v-if="show(CourseInfoDlField.Periods) && periods.length > 0" :class="{ spacing }">
      <div v-if="periods.length > 1">
        <div v-for="period in periods" :key="period.keywords[0]">
          <dt>{{ period.name }}</dt>
          <dd>
            {{ period.begins | dateRange(period.ends) }}
            <span v-if="period.lessoncount">
              ({{ $tc('courseInfoDl.lessons', period.lessoncount) }})
            </span>
          </dd>
        </div>
      </div>
      <div v-else>
        <dt>
          {{
            (periods[0].begins | date) === (periods[0].ends | date)
              ? $t('courseInfoDl.period')
              : periods[0].name + ' '
          }}
        </dt>
        <dd>
          {{ periods[0].begins | dateRange(periods[0].ends) }}
          <span v-if="periods[0].lessoncount">
            ({{ $tc('courseInfoDl.lessons', periods[0].lessoncount) }})
          </span>
        </dd>
      </div>
    </div>

    <div v-if="show(CourseInfoDlField.Weekdays)" :class="{ spacing }">
      <dt>{{ $t('courseInfoDl.weekdays') }}</dt>
      <dd v-for="day in formattedWeekdays" :key="String(day.weekday)" class="weekday">
        <span class="weekday-short" v-if="day.weekday">
          {{ $d(day.weekday, 'weekdayShort') }}
        </span>
        <span>
          <p v-for="{ begins, ends } in day.times" :key="`${begins}-${ends}`">
            {{ begins }}&ndash;{{ ends }}
          </p>
        </span>
      </dd>
    </div>

    <div v-if="show(CourseInfoDlField.MobileMinimal)" :class="{ spacing }">
      <div v-if="isMobileOneLinerPossible">
        <template v-for="day in formattedWeekdays">
          <span v-if="day.weekday" class="weekday-short-minimal" :key="String(day.weekday)">{{
            $d(day.weekday, 'weekdayShort')
          }}</span>
        </template>
        <div v-if="formattedWeekdays[0].times[0]" class="time">
          {{ formattedWeekdays[0].times[0].begins }}&ndash;{{ formattedWeekdays[0].times[0].ends }}
        </div>
      </div>
      <dd v-else v-for="day in formattedWeekdays" :key="String(day.weekday)" class="weekday">
        <span class="weekday-short" v-if="day.weekday">
          {{ $d(day.weekday, 'weekdayShort') }}
        </span>
        <span>
          <p v-for="{ begins, ends } in day.times" :key="`${begins}-${ends}`">
            {{ begins }}&ndash;{{ ends }}
          </p>
        </span>
      </dd>
      <span v-if="periods[0].begins">
        {{ $t('courseInfoDl.starts') }}
        {{ periods[0].begins | dateRange(periods[0].begins) }}
        <span v-if="periods[0].lessoncount">
          ({{ $tc('courseInfoDl.lessons', periods[0].lessoncount) }})
        </span>
      </span>
    </div>

    <div v-if="show(CourseInfoDlField.Ectscredits)" :class="{ spacing }">
      <dt>{{ $t('courseInfoDl.ectscredits') }}</dt>
      <dd>{{ course.ectscredits }}</dd>
    </div>

    <div v-if="show(CourseInfoDlField.Teacher) && $t('courseInfoDl.teacher')" :class="{ spacing }">
      <dt>{{ $t('courseInfoDl.teacher') }}</dt>
      <dd>{{ course.teacher }}</dd>
    </div>

    <div v-if="show(CourseInfoDlField.Location)" :class="{ spacing }">
      <dt>{{ $t('courseInfoDl.location') }}</dt>
      <dd>
        <address :class="{ 'single-line': locationSingleLine }">
          <span v-if="location.name" class="name">{{ location.name }}</span>
          <span v-if="location.address">{{ location.address }}</span>
          <span v-if="location.city">{{ location.city }}</span>
        </address>
      </dd>
      <dd v-if="location.accessibility">
        <div
          class="accessibility"
          v-if="location.accessibility"
          v-dompurify-html="location.accessibility"
        />
      </dd>
      <dd v-if="locationMapLink && location.latlon" class="location-map-link">
        <a @click="$emit('map-modal-open')">{{ $t('courseInfoDl.showOnMap') }}</a>
      </dd>
    </div>

    <div v-if="show(CourseInfoDlField.Teachingformat)" :class="{ spacing }">
      <dt>{{ $t('courseInfoDl.teachingFormat') }}</dt>
      <dd>{{ getCatalogItemName('teachingformat') }}</dd>
    </div>

    <div
      v-if="
        show(CourseInfoDlField.RegistrationTime) &&
        registrationbegins &&
        registrationendssoft &&
        !participantcount.registrationopen
      "
      :class="{ spacing }"
    >
      <dt>{{ $t('courseInfoDl.registrationRange') }}</dt>
      <dd>{{ registrationbegins | dateTime }}&ndash;{{ registrationendssoft | midnight }}</dd>
    </div>

    <div
      v-if="
        show(CourseInfoDlField.RegistrationTime) &&
        registrationbegins &&
        !registrationendssoft &&
        registrationbegins > new Date()
      "
      :class="{ spacing }"
    >
      <dt>{{ $t('courseInfoDl.registrationBegins') }}</dt>
      <dd>
        {{ registrationbegins | dateTime }}
      </dd>
    </div>

    <div
      v-if="show(CourseInfoDlField.RegistrationTime) && participantcount.registrationopen"
      :class="{ spacing, registrationopen }"
    >
      {{ $t('courseInfoDl.registrationOpen') }}
    </div>

    <div
      v-if="
        show(CourseInfoDlField.RegistrationTime) &&
        registrationbegins &&
        registrationendssoft &&
        registrationbegins < new Date() &&
        registrationendssoft > new Date()
      "
      :class="{ spacing }"
    >
      <dt>{{ $t('courseInfoDl.registrationEnds') }}</dt>
      <dd>
        {{ registrationendssoft | dateTime }}
      </dd>
    </div>

    <div v-if="show(CourseInfoDlField.PriceClasses)" :class="{ spacing }">
      <dt>{{ $t('courseInfoDl.priceClasses') }}</dt>
      <dd v-for="price in prices" :key="price.id">
        {{ price.name }} {{ $n(price.amount / 100, 'currency') }}
        <dl v-if="price.installmentgroups">
          <div v-for="(installmentgroup, i) in price.installmentgroups" :key="i">
            <dt>{{ installmentgroup.name }}</dt>
            <dd
              class="installment"
              v-for="installment in installmentgroup.installments"
              :key="installment.id"
            >
              {{ installment.name }} {{ $n(installment.amount / 100, 'currency') }}
            </dd>
          </div>
        </dl>
      </dd>
    </div>
  </dl>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api';
import { includes, isEmpty, sortBy } from 'lodash/fp';

import { HellewiCourseLesson, HellewiCoursePartial } from '../api';
import { formatWeekdays, FormatedWeekday } from '../utils/date-utils';

export enum CourseInfoDlField {
  Location,
  Period,
  Periods,
  PriceClasses,
  RegistrationTime,
  Teacher,
  Weekdays,
  MobileMinimal,
  Ectscredits,
  Teachingformat
}

export default defineComponent({
  props: {
    course: {
      type: Object as PropType<HellewiCoursePartial>,
      required: true
    },
    lesson: {
      type: Object as PropType<HellewiCourseLesson>,
      required: false
    },
    fields: {
      type: Array as PropType<CourseInfoDlField[]>,
      required: true
    },
    spacing: {
      type: Boolean,
      required: true
    },
    locationSingleLine: {
      type: Boolean,
      required: false
    },
    locationMapLink: {
      type: Boolean,
      required: false
    },
    horizontal: {
      type: Boolean,
      required: false
    }
  },
  setup: (props) => {
    // if lesson is given, use information from there, otherwise from course
    const { begins, ends, location } = props.lesson ? props.lesson : props.course;
    const beginsAndEndsFromLesson = Boolean(props.lesson);

    const show = (field: CourseInfoDlField): boolean => {
      // don't show if props doesn't include this field
      if (!includes(field, props.fields)) {
        return false;
      }
      switch (field) {
        case CourseInfoDlField.Period:
          return Boolean(begins) && Boolean(ends);
        case CourseInfoDlField.Periods:
          return !isEmpty(props.course.periods);
        case CourseInfoDlField.Weekdays:
          return !isEmpty(props.course.days);
        case CourseInfoDlField.MobileMinimal:
          return !isEmpty(props.course.days);
        case CourseInfoDlField.Location:
          return Boolean(location);
        case CourseInfoDlField.RegistrationTime:
          return Boolean(props.course.registrationbegins);
        case CourseInfoDlField.PriceClasses:
          return !isEmpty(props.course.prices);
        case CourseInfoDlField.Teacher:
          return Boolean(props.course.teacher);
        case CourseInfoDlField.Ectscredits:
          return Boolean(props.course.ectscredits);
        case CourseInfoDlField.Teachingformat:
          return Boolean(props.course.catalogitems.some((ci) => ci.type === 'teachingformat'));
      }
    };

    const getCatalogItemName = (type: string) =>
      props.course.catalogitems.find((ci) => ci.type === type)?.name;

    const formattedWeekdays = computed<FormatedWeekday[]>(() =>
      sortBy('weekday', formatWeekdays(props.course?.days))
    );

    // filter out terms from periods
    const periods =
      props.course.periods?.filter((p) =>
        p.keywords?.some((keyword) => keyword.includes('period'))
      ) || [];

    const isMobileOneLinerPossible = computed<boolean>(
      () =>
        formattedWeekdays.value.length > 0 &&
        formattedWeekdays.value[0].times.length === 1 &&
        formattedWeekdays.value.every(
          ({ times }) =>
            times.length === 1 &&
            times[0].begins === formattedWeekdays.value[0].times[0].begins &&
            times[0].ends === formattedWeekdays.value[0].times[0].ends
        )
    );

    return {
      CourseInfoDlField,
      formattedWeekdays,
      getCatalogItemName,
      beginsAndEndsFromLesson,
      show,
      ...props.course,
      periods,
      begins,
      ends,
      location,
      isMobileOneLinerPossible
    };
  }
});
</script>
