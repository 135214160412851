<style lang="scss" scoped>
.collapse-title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0;
  padding-right: 0;
}

.collapse-title-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  margin-top: 0.3rem;
  flex-wrap: wrap;
}

.collapse-title-icon {
  margin-right: 0.3rem;
}

.collapse-title-detail {
  margin-right: 1.5rem;
}

.collapse-card {
  box-shadow: none;
  border: none !important;
}

.card-header {
  box-shadow: none;
  cursor: default;
}

.collapse-card-header {
  box-shadow: none;
  cursor: pointer;

  &:hover,
  &:hover .card-header-title,
  &:hover a:hover {
    color: $primary;
    color: var(--brandcolor);
  }
}

.period-name {
  font-weight: 600;
}

.weekdayLong {
  text-transform: capitalize;
}
.card-header-toggle {
  display: flex;
  align-items: center;

  .card-header-icon {
    padding-left: 0;
    @media (min-width: $tablet) {
      padding-left: 0.5rem;
    }
  }
}
</style>

<style lang="scss">
div.lessons-table div.table-wrapper table.table > thead {
  display: none !important;
}

div.lessons-table tbody tr > td {
  flex-direction: row-reverse;
}
</style>

<template>
  <b-collapse
    class="collapse-card"
    animation="slide"
    :aria-id="lessons.name + lessons.begins"
    :open="false"
  >
    <div
      slot="trigger"
      slot-scope="props"
      :class="{ 'card-header': true, 'collapse-card-header': lessons.lessons }"
      role="button"
      :aria-controls="lessons.name + lessons.begins"
    >
      <span class="card-header-title collapse-title-container">
        <span v-if="lessons.name" class="period-name">{{
          (lessons.begins | date) === (lessons.ends | date)
            ? $t('courseInfoDl.period')
            : lessons.name + ' '
        }}</span>
        <div class="collapse-title-details">
          <span class="collapse-title-detail" v-if="lessons.begins && lessons.ends">
            {{ lessons.begins | dateRange(lessons.ends) }}
          </span>
          <span class="collapse-title-detail" v-if="lessons.lessoncount">
            ({{ $t('lessons') }}: {{ lessons.lessoncount }})
          </span>
        </div>
      </span>
      <div v-if="lessons.lessons" class="card-header-toggle">
        <div>{{ props.open ? $t('close') : $t('open') }}</div>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"> </b-icon>
        </a>
      </div>
    </div>
    <div class="card-content" v-if="lessons.lessons">
      <div class="content">
        <b-table
          v-if="lessons.lessons"
          class="lessons-table"
          :data="lessons.lessons"
          :mobile-cards="true"
        >
          <b-table-column custom-key="day">
            <template v-slot:header="{}">
              {{ $t('registration.date') }}
            </template>
            <template v-slot="props">
              <span class="weekdayLong">
                {{ $d(new Date(props.row.begins), 'weekdayLong') }}
                {{ new Date(props.row.begins) | dateRange(new Date(props.row.ends)) }}
              </span>
            </template>
          </b-table-column>
          <b-table-column custom-key="time">
            <template v-slot:header="{}">
              {{ $t('registration.time') }}
            </template>
            <template v-slot="props">
              {{ new Date(props.row.begins) | timeRange(new Date(props.row.ends)) }}
            </template>
          </b-table-column>
          <b-table-column custom-key="place">
            <template v-slot:header="{}">
              {{ $t('locationgroup') }}
            </template>
            <template v-slot="props">
              <span v-if="props.row.location">
                {{
                  [props.row.location.name, props.row.location.address].filter(Boolean).join(', ')
                }}
              </span>
              <span v-else>
                {{ $t('lessonsCollapse.placeNotProvided') }}
              </span>
            </template>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </b-collapse>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api';

import { HellewiCourse, HellewiCoursePeriod } from '../../api';

export default defineComponent({
  props: {
    period: {
      type: Object as PropType<HellewiCoursePeriod | undefined>
    },
    course: {
      type: Object as PropType<HellewiCourse | undefined>
    }
  },
  setup(props) {
    const lessons = computed(() => {
      if (props.period) {
        return {
          name: props.period.name,
          begins: props.period.begins,
          ends: props.period.ends,
          lessoncount: props.period.lessoncount,
          lessons:
            props.period.lessons && props.period.lessons?.length > 0
              ? props.period.lessons
              : undefined
        };
      }
      if (props.course) {
        return {
          name: undefined,
          begins: props.course.begins,
          ends: props.course.ends,
          lessoncount: props.course.lessons?.length,
          lessons:
            props.course.lessons && props.course.lessons?.length > 0
              ? props.course.lessons
              : undefined
        };
      }
      return {};
    });
    return { lessons };
  }
});
</script>
