<!-- similar component CourseAvailability.vue -->

<style lang="scss" scoped>
.status {
  display: flex;
  align-items: center;
}

.status--actualNotStarted,
.status--actualInProgress {
  color: $available;
}

.status--spareNotStarted,
.status--spareInProgress {
  color: $sparesAvailable;
}

.status--actualEnded,
.status--spareEnded {
  color: $lightGray;
}

.status--interrupted,
.status--cancelled {
  color: $full;
}
</style>

<template>
  <div class="status" v-if="status">
    <b-icon icon="circle" size="is-small" :class="`status--${status}`" />
    <span>{{ $t(`registrationStatus.${status}`) }}</span>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from '@vue/composition-api';

import {
  HellewiCourseStatus,
  PurchaseProductNumber,
  PurchaseProductStatus
} from '../../../frontend-src/api';

export enum RegistrationStatus {
  ActualNotStarted = 'actualNotStarted',
  SpareNotStarted = 'spareNotStarted',
  ActualInProgress = 'actualInProgress',
  SpareInProgress = 'spareInProgress',
  ActualEnded = 'actualEnded',
  SpareEnded = 'spareEnded',
  Cancelled = 'cancelled',
  Interrupted = 'interrupted'
}

export const getRegistrationStatus = (
  courseStatuses: HellewiCourseStatus[],
  productStatuses: PurchaseProductStatus[]
): RegistrationStatus | undefined => {
  const spare = productStatuses.includes(PurchaseProductStatus.Spare);
  if (
    courseStatuses.includes(HellewiCourseStatus.Cancelled) ||
    productStatuses.includes(PurchaseProductStatus.Cancelled)
  ) {
    return RegistrationStatus.Cancelled;
  } else if (
    courseStatuses.includes(HellewiCourseStatus.Interrupted) ||
    productStatuses.includes(PurchaseProductStatus.Interrupted)
  ) {
    return RegistrationStatus.Interrupted;
  } else if (courseStatuses.includes(HellewiCourseStatus.NotYetStarted) && spare) {
    return RegistrationStatus.SpareNotStarted;
  } else if (courseStatuses.includes(HellewiCourseStatus.NotYetStarted) && !spare) {
    return RegistrationStatus.ActualInProgress;
  } else if (courseStatuses.includes(HellewiCourseStatus.InProgress) && spare) {
    return RegistrationStatus.SpareInProgress;
  } else if (courseStatuses.includes(HellewiCourseStatus.InProgress) && !spare) {
    return RegistrationStatus.ActualInProgress;
  } else if (courseStatuses.includes(HellewiCourseStatus.Ended) && spare) {
    return RegistrationStatus.SpareEnded;
  } else if (courseStatuses.includes(HellewiCourseStatus.Ended) && !spare) {
    return RegistrationStatus.ActualEnded;
  } else if (courseStatuses.includes(HellewiCourseStatus.RegistrationToLessons)) {
    return RegistrationStatus.ActualInProgress;
  }
  return undefined;
};

// for cart view, we'll only want the future tense ("olet saamassa paikan")
// even if the course was in progress
export const getRegistrationStatusFromCart = (spare: boolean | undefined): RegistrationStatus =>
  spare ? RegistrationStatus.SpareNotStarted : RegistrationStatus.ActualNotStarted;

export default defineComponent({
  props: {
    registration: {
      type: Object as PropType<PurchaseProductNumber>,
      required: true
    },
    fromCart: {
      type: Boolean,
      required: true
    }
  },
  setup: (props) => {
    const status = computed<RegistrationStatus | undefined>(() => {
      if (props.fromCart) {
        return getRegistrationStatusFromCart(props.registration.spare);
      } else if (props.registration.course) {
        return getRegistrationStatus(
          props.registration.course.statuses,
          props.registration.statuses
        );
      } else {
        return undefined;
      }
    });
    return { status };
  }
});
</script>
